import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        The NEWA obliquebanded leafroller tool was authored by Harvey Reissig
        and Art Agnello, Department of Entomology, Cornell University.
      </p>
    </>
  )
}
