import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Baskerville, G.L. and Emin, P. 1969. Rapid Estimation of Heat
        Accumulation from Maximum and Minimum Temperatures. Ecology. 50:
        514-517.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Onstad, D.W., Reissig, W.H., and Shoemaker, C.A. 1985. Phenology and
        management of the obliquebanded leafroller (Lepidoptera: Tortricidae) in
        apple orchards. J. Econ. Entomol. 78: 1455-1462.
      </p>
    </>
  )
}
